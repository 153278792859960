(function(Moderizr){
	var getExpandHandler = function (event) {
		var handler = function () {
			//event listeners have this bound to window.
			var _event = event;
			_event.classList.add('expanded');
			
			// can we do this in css? still not giving up.
			requestAnimationFrame(function () {
				var expanded = _event.querySelector('.event-expanded');
				expanded.style.opacity = 1;
				if (Modernizr.csstransforms3d) {
					setVendorCss(expanded, 'transform', 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)');
				}
			});

			//loads image if it has been deferred.
			var images = _event.querySelectorAll('.event-images img');
			for (var i = 0, ii = images.length; i < ii; i++) {
				var imageElement = images[i];
				if (!imageElement.src && imageElement.dataset.src) {
					var image = new Image();
					image.onload = function(){ imageElement.src = image.src };
					image.src = imageElement.dataset.src;
				}
			}

			_event.removeEventListener('click', handler, false);
		};

		return handler;
	};

	var events = document.getElementsByClassName('event');
	for (var i = 0, ii = events.length; i < ii; i++) {
		events[i].addEventListener('click', getExpandHandler(events[i]), false);
	}

}(Modernizr));