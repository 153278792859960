~function(){
	var ROOT_EM = window.parseInt(window.getComputedStyle(document.body).fontSize);
	var wrapper = document.getElementById('about-shields');
	//live collection. no need to update.
	var shields = wrapper.getElementsByClassName('shield');

	var init = function () {
		for (var i = 0, ii = shields.length; i < ii; i++) {
			var shield = shields[i];
			shield.style.maxWidth = ((1.0-i/ii) * 5*ROOT_EM + 4*ROOT_EM) + 'px';
			shield.style.zIndex = (ii - 1-i);
		}

		var scrollHandler = throttle( function(){
			var scroll = document.documentElement && document.documentElement.scrollTop
				|| document.body.scrollTop;
			if ( scroll + window.innerHeight*0.6666 > wrapper.offsetTop) {
				for (var i = 0, ii = shields.length; i < ii; i++) {
					setVendorCss(shields[i], 'transform', 'translate3d('+ ((wrapper.offsetWidth-10) * i/ii) +'px, 0px,0px)' );
				}	
				window.removeEventListener('scroll', scrollHandler, false );
			}
		}, 300, true);
		window.addEventListener('scroll', scrollHandler, false );
	}

	init();
}();