var initMaps = function () {
	var style = [
		{
			"featureType": "administrative",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"lightness": "-100"
				},
				{
					"saturation": "-45"
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"lightness": "100"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
				{
					"color": "#fdfaf8"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#d6cfcb"
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "##000000"
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "off"
				},
				{
					"lightness": 20
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "all",
			"stylers": [
				{
					"lightness": 20
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#FEC042"
				}
			]
		},
		{			"featureType": "road.highway",
			"elementType": "labels",
			"stylers": [
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#000000"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#e5dcd0"
				}
			]
		},
		{
			"featureType": "road.local",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#fbfaf7"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#e8e1d6"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "all",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#948a84"
				}
			]
		}
	];

	var parkEdelweiss = {lat: 41.0277909, lng: -85.1829402};
	var map = new google.maps.Map(document.getElementById('edelweiss-map'), {
		zoom: 12
		, center: {lat: 41.063038, lng: -85.156686}
		, styles: style
		, mapTypeControl: false
		, scrollwheel: false,
	});
	var marker = new google.maps.Marker({
		position: parkEdelweiss
		, map: map
	});
};