// (function(){
// 	var main = document.querySelector('main');
// 	var header = document.querySelector('header');
// 	var headerClone = header.cloneNode(true);

// 	headerClone.setAttribute('class', 'sticky')
// 	document.body.insertBefore(headerClone, header);

// 	function init ()
// 	{
// 		if (window.scrollY > main.offsetTop) {
// 			headerClone.style.transstform = 'translateY(0px)';
// 		} else  {
// 			headerClone.style.transform = '';
// 		}
// 	}
// 	window.addEventListener('scroll', init, false);
// }());w