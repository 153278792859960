~function(){
	var findAncestorWithTag = function(el, tagName) {
		while ((el = el.parentElement) && el.tagName.toUpperCase() != tagName.toUpperCase());
		return el;
	}
	
	var header = document.getElementById('header');
	var out = document.getElementById('bugoutput');
	var contentsList = document.getElementById('contents-list');
	var sections = document.getElementsByTagName('section');
	for (var i = 0, ii = sections.length; i < ii; i++) {
		var section = sections[i];
		var h2 = section.getElementsByTagName('h2')[0];
		if (h2)
			contentsList.insertAdjacentHTML('beforeend',
				'<li>'
				+ '<a class="nav-link" href="#'+ section.id +'">'
				+ h2.innerText
				+ '</a>'
				+ '<li>'
			);
	}

  var getWindowHeight = function(){
    return window.innerHeight;
  };
  var updateHeaderHeight = function(){
    header.style.height = window.innerHeight + "px";
  };
  var handleOrientationChange = getStateChangedHandler({
    wait: 100,
    attempts: 5,
    state: getWindowHeight,
    success: updateHeaderHeight
  });
  // prevents obnoxious 100vh appbar jank on mobile
	window.addEventListener('orientationchange', function(){
    updateHeaderHeight();
    handleOrientationChange();
  });

  updateHeaderHeight();
}();